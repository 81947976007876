.h1 {
    accent-color: rgb(73, 79, 223);
    box-sizing: border-box;
    color: rgb(25, 28, 31);
    color-scheme: light;
    display: block;
    font-family: "Aeonik Pro Capitalised", sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-size: 52.72px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 900;
    height: 52.7188px;
    hyphenate-limit-chars: 10;
    hyphens: auto;
    letter-spacing: -0.6px;
    line-height: 52.72px;
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    min-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    text-align: center;
    unicode-bidi: isolate;
    width: 100%;
    -webkit-font-smoothing: antialiased;
}

.gridMenu {
    accent-color: rgb(73, 79, 223);
    box-sizing: border-box;
    color: rgb(25, 28, 31);
    color-scheme: light;
    column-gap: 24px;
    display: grid;
    grid-template-columns: auto auto auto;
    font-family: Inter, sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-size: 16px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 400;
    grid-template-columns: 317.328px 317.336px 317.336px;
    height: 1384px;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    row-gap: 24px;
    unicode-bidi: isolate;
    width: 1000px;
    -webkit-font-smoothing: antialiased;
}

.gridElement {
    box-sizing: border-box;
    color: rgb(25, 28, 31);
    color-scheme: light;
    display: flex;
    font-family: Inter, sans-serif;
    font-feature-settings: normal;
    font-kerning: auto;
    font-optical-sizing: auto;
    font-size: 16px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    font-variation-settings: normal;
    font-weight: 400;
    justify-content: space-between;
    line-height: normal;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    outline-color: rgba(0, 0, 0, 0);
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    text-align: left;
    transition-behavior: normal;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0.3, 0.8, 0.6);
    unicode-bidi: isolate;
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    -webkit-font-smoothing: antialiased;
}
