@tailwind base;
@tailwind components;
@tailwind utilities;

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
